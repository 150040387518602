import styles from './ProductTilesGroup.module.scss';
import PropTypes from 'prop-types';
import { ProductTilesSlider } from 'components/objects/productTile';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import ReactResizeDetector from 'react-resize-detector';

// The value was found experimentally, on PLP, so number of tiles is equal to products tiles in row of products list (grid view).
const productTileCustomMinWidth = 152.375;

const ProductsGroupLastViewed = ({
  id,
  headerTextKey,
  links = null,
  products,
  withMargins,
}) => {
  const placeholder = <Placeholder className={styles.headingPlaceholder} />;
  const headingId = id ? id + 'head' : null;

  return (
    <section className={`${styles.wrapper} ${withMargins ? styles.withMargins : ''}`}>
      <header role={links ? null : 'presentation'}>
        <h2 className={styles.heading} id={headingId}>
          <SimpleText textKey={headerTextKey} placeholder={placeholder} />
        </h2>
        {links}
      </header>
      <div id={id} aria-describedby={headingId}>
        <ReactResizeDetector handleWidth>
          {({ width }) => <ProductTilesSlider products={products} tilesPerView={Math.floor(width / productTileCustomMinWidth) || 1} />}
        </ReactResizeDetector>
      </div>
    </section>
  );
};

ProductsGroupLastViewed.propTypes = {
  id: PropTypes.string,
  headerTextKey: PropTypes.string.isRequired,
  links: PropTypes.node,
  products: PropTypes.array.isRequired,
  withMargins: PropTypes.bool,
};

export default ProductsGroupLastViewed;
