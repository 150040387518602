import PropTypes from 'prop-types';
import ProductsGroupLastViewed from '../productTilesGroup/ProductTilesGroupLastViewed';
import ProductsGroup from '../productTilesGroup';
import { useIsMobileViewport } from 'utils/hooks';

const LastViewedProductsGroup = ({ id, clearLastViewedProducts, ...props }) => {
  const isMobile = useIsMobileViewport();

  if(isMobile)
    return <ProductsGroupLastViewed id={`lastViewedProducts${id ? '_' + id : ''}`} headerTextKey="LastViewedProducts_Header" {...props} />;

  return <ProductsGroup id={`lastViewedProducts${id ? '_' + id : ''}`} headerTextKey="LastViewedProducts_Header" {...props} />;
};

LastViewedProductsGroup.propTypes = {
  id: PropTypes.string,
  clearLastViewedProducts: PropTypes.func,
};

export default LastViewedProductsGroup;
